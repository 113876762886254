import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
          EbillX is a leading cloud-based solution designed to streamline your accounting and inventory management processes. We understand the challenges businesses face in managing finances and tracking stock efficiently. That's why we've created a powerful platform that simplifies complex tasks, saving you time and money.
            <br />
            <br />
            Our intuitive software empowers businesses of all sizes to handle invoicing, billing, inventory management, and more with ease. With EbillX, you can access your data anytime, anywhere, and make informed decisions based on real-time insights.
          <br/>
          <br />
          We are committed to providing exceptional customer support and ensuring your complete satisfaction. Join the thousands of businesses that trust EbillX to optimize their operations.
          
          </p>
         
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
