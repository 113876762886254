import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
          "All-in-One billing",
          "Customised invoices",
          "Inventory Management",
          "Business Management",
          "e-invoicing & e-way billing",
          "Online store & digital catalog",
          "Low Stock Inventory Alert",
          "Expiry Alert",
          "Retail & Wholesale price setup",
          "Set invoice prefix",
          "Credit limit for customers",
          "Loan account management",
          "Discount Management",
          "Bill wise payment tracking",
          "Balance sheet",
          "Profit and loss",
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 50,
      }}
    />
  );
}

export default Type;
